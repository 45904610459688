import React from 'react';
import { connect } from 'react-redux';
import Button from '@Components/form/Button';
import ImageSrc from '@Images/announcements/zero_percent@2x.jpg';

import { useTranslation } from 'react-i18next';

const OiZeroPercent = ({ onHide }) => {
   /**
   * hooks
   */
  const { t } = useTranslation();

  /**
   * render
   */
  return (
    <div className="oi-zero-percent">
      <img className="oi-zero-percent__image" src={ImageSrc}/>

      <div className="oi-zero-percent__content">

        <p className="oi-zero-percent__text">
          {t('modals.zeroPercentAnnouncement')}
        </p>

        <div className="oi-zero-percent__button-container">
            <Button
              fullSize
              className="oi-zero-percent__button"
              type="primary"
              onClick={() => onHide()}>
              {t('modals.doneButton')}
            </Button>
          </div>        
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const actions = {};

export default connect(mapStateToProps, actions)(OiZeroPercent);