import React from 'react';

import OiZeroPercent from '@Components/shared/OiZeroPercent';

import OiModal from '../shared/OiModal';

const OiZeroPercentModal = ({ onHide }) => {
  /**
   * render
   */
  return (
    <OiModal
      logo
      className="oi-zero-percent-modal"
      footer={false}
      onHide={onHide}
      onBackdropClick={() => {
        console.log('backdrop click');
        onHide && onHide();
      }}      
      >
        <OiZeroPercent onHide={onHide}/>
    </OiModal>
  );
};

export default OiZeroPercentModal;
